/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminIncludeBookingDTO } from '../models/AdminIncludeBookingDTO';
import type { AdminIncludeStatusBookingDTO } from '../models/AdminIncludeStatusBookingDTO';
import type { ApproveDeclineIncludeBookingDTO } from '../models/ApproveDeclineIncludeBookingDTO';
import type { ApproveInviteBookingDTO } from '../models/ApproveInviteBookingDTO';
import type { BookingCheckInTalentDTO } from '../models/BookingCheckInTalentDTO';
import type { BookingConfigResponseDTO } from '../models/BookingConfigResponseDTO';
import type { BookingResponseDTO } from '../models/BookingResponseDTO';
import type { CancelIncludeBookingDTO } from '../models/CancelIncludeBookingDTO';
import type { CreateBookingDTO } from '../models/CreateBookingDTO';
import type { CreateInviteBookingDTO } from '../models/CreateInviteBookingDTO';
import type { DeleteInviteBookingDTO } from '../models/DeleteInviteBookingDTO';
import type { IncludeBookingDTO } from '../models/IncludeBookingDTO';
import type { InviteBookingDTO } from '../models/InviteBookingDTO';
import type { OwnerCancelIncludeBookingDTO } from '../models/OwnerCancelIncludeBookingDTO';
import type { PageBookingInviteListResponseDTO } from '../models/PageBookingInviteListResponseDTO';
import type { PageBookingListResponseDTO } from '../models/PageBookingListResponseDTO';
import type { TalentCheckInBookingDTO } from '../models/TalentCheckInBookingDTO';
import type { UpdateBookingDTO } from '../models/UpdateBookingDTO';
import type { UpdateBookingStatusDTO } from '../models/UpdateBookingStatusDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BookingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Booking
     * Before creating a Booking, make a payment and if the payment is successful, then create a Booking.
     * @returns BookingResponseDTO
     * @throws ApiError
     */
    public createBooking({
        formData,
    }: {
        formData: CreateBookingDTO,
    }): CancelablePromise<BookingResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Not venue`,
                401: `Invalid authorization data`,
                403: `Only a user with a client role can create a Booking.`,
            },
        });
    }

    /**
     * Create Invite Booking
     * @returns BookingResponseDTO
     * @throws ApiError
     */
    public createInviteBooking({
        formData,
    }: {
        formData: CreateInviteBookingDTO,
    }): CancelablePromise<BookingResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/invite',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Not venue`,
                401: `Invalid authorization data`,
                403: `Only a user with a client role can create a Booking.`,
            },
        });
    }

    /**
     * Cancel Booking
     * @returns any
     * @throws ApiError
     */
    public cancelBooking({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/cancel',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only a user with the owner can cancel the booking.`,
            },
        });
    }

    /**
     * Done Booking
     * @returns any
     * @throws ApiError
     */
    public doneBooking({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/done',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only a user with the owner can done the booking. The current date should be greater than booking.endDate.`,
            },
        });
    }

    /**
     * Update Booking
     * @returns BookingResponseDTO
     * @throws ApiError
     */
    public updateBooking({
        id,
        formData,
    }: {
        /**
         * Id
         */
        id: string,
        formData: UpdateBookingDTO,
    }): CancelablePromise<BookingResponseDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/booking/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Invalid authorization data`,
                403: `Only a user with the owner or admin can update the data.`,
            },
        });
    }

    /**
     * Booking
     * @returns BookingResponseDTO Booking Data
     * @throws ApiError
     */
    public getBooking({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<BookingResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `Booking not found`,
            },
        });
    }

    /**
     * Update Booking Status
     * @returns any
     * @throws ApiError
     */
    public updateBookingStatus({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: UpdateBookingStatusDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/booking/{id}/status',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or booking.changeBooking can execute the request.`,
            },
        });
    }

    /**
     * Like Booking
     * @returns any
     * @throws ApiError
     */
    public likeBooking({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/like',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `Booking not found`,
            },
        });
    }

    /**
     * Unlike Booking
     * @returns any
     * @throws ApiError
     */
    public unlikeBooking({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/unlike',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `Booking not found`,
            },
        });
    }

    /**
     * Invite User Booking
     * @returns any
     * @throws ApiError
     */
    public inviteUserBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: InviteBookingDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/invite',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Only the owner of Booking can invite a user. You cannot invite a user who has already been invited or has applied for participation. You can invite users with the talent role. Fee cannot be less than rateTalent.`,
                401: `Invalid authorization data`,
                404: `Booking not found, User not found`,
            },
        });
    }

    /**
     * Delete Invite User Booking
     * @returns any
     * @throws ApiError
     */
    public deleteInviteUserBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: DeleteInviteBookingDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/booking/{id}/invite',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Only the owner of Booking can delete invite a user.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * User Approve Invite Booking
     * @returns any
     * @throws ApiError
     */
    public userApproveInviteBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: ApproveInviteBookingDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/invite/approve',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `There is no user in the invite list. The maximum number of confirmed participants has been reached. Status not pending`,
                401: `Invalid authorization data`,
                404: `Booking not found`,
            },
        });
    }

    /**
     * User Decline Invite Booking
     * @returns any
     * @throws ApiError
     */
    public userDeclineInviteBooking({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/invite/decline',
            path: {
                'id': id,
            },
            errors: {
                400: `There is no user in the invite list.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Include User Booking
     * @returns any
     * @throws ApiError
     */
    public includeUserBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: IncludeBookingDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/include',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The user is in the invite list. The maximum number of confirmed participants has been reached.`,
                401: `Invalid authorization data`,
                404: `Booking not found`,
            },
        });
    }

    /**
     * Admin Include User Booking
     * @returns any
     * @throws ApiError
     */
    public adminIncludeUserBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: AdminIncludeBookingDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/admin/include',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The user is in the invite list. The maximum number of confirmed participants has been reached.`,
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or booking.includeUser can execute the request.`,
                404: `Booking not found.`,
            },
        });
    }

    /**
     * Admin Include User Status Booking
     * @returns BookingResponseDTO
     * @throws ApiError
     */
    public adminIncludeUserStatusBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: AdminIncludeStatusBookingDTO,
    }): CancelablePromise<BookingResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/admin/include-status',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The maximum number of confirmed participants has been reached.`,
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or booking.changeBooking can execute the request.`,
                404: `Booking not found.`,
            },
        });
    }

    /**
     * Cancel Include User Booking
     * @returns any
     * @throws ApiError
     */
    public cancelIncludeUserBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: CancelIncludeBookingDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/include/cancel',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Not user or user status is checkIn,paid,done and booking.startDate < new Date()`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Owner Cancel Include User Booking
     * @returns any
     * @throws ApiError
     */
    public ownerCancelIncludeUserBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: OwnerCancelIncludeBookingDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/owner/include/cancel',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `booking.startDate < new Date().`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Approve Include Booking
     * To approve a talent, use a payment request.
     * @returns any
     * @throws ApiError
     */
    public approveIncludeBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: ApproveDeclineIncludeBookingDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/include/approve',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Only the booking owner can approve the include user. Include status is not pending. Talent is approved automatically after payment.`,
                401: `Invalid authorization data`,
                404: `Booking not found, User not found`,
            },
        });
    }

    /**
     * Decline Include Booking
     * @returns any
     * @throws ApiError
     */
    public declineIncludeBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: ApproveDeclineIncludeBookingDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/include/decline',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Only the booking owner can decline the include user. Include status is not pending.`,
                401: `Invalid authorization data`,
                404: `Booking not found`,
            },
        });
    }

    /**
     * Talent CheckIn Booking
     * @returns any
     * @throws ApiError
     */
    public talentCheckInBooking({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: TalentCheckInBookingDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/talent-check-in',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Booking not found. The talent's status is not approved. The Booking type must be event. The current date should be greater than booking.startDate and less than booking.startDate + 30 minutes. Far from the gathering place, the distance should be less than 50 meters.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking CheckIn Talent
     * @returns any
     * @throws ApiError
     */
    public bookingCheckInTalent({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: BookingCheckInTalentDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/{id}/check-in',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Only the booking owner can check-in talent. The talent's status is not approved. The current date should be greater than booking.startDate.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking List
     * @returns PageBookingListResponseDTO List of Booking
     * @throws ApiError
     */
    public getBookingList({
        search,
        city,
        cities,
        venue,
        isOnlyLike,
        isInvite,
        isFirstTalentInfo,
        isOwner,
        isIncluded,
        isInvited,
        isMyBooking,
        eventType,
        type,
        status,
        statuses,
        userStatus,
        userStatuses,
        createdAtFrom,
        createdAtTo,
        startDateFrom,
        startDateTo,
        userId,
        page = 1,
        limit = 10,
        pageFilter,
        isCount,
        sort = 'createdAt',
        sortDirection = -1,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Send the _id
         */
        city?: string,
        /**
         * Send the _id
         */
        cities?: Array<string>,
        /**
         * Send the _id
         */
        venue?: string,
        isOnlyLike?: boolean,
        isInvite?: boolean,
        /**
         * Get the data of 1 talent from includedTalents or invited. We will get the data if the status is 1 of pending,waitingPayment,approved,checkIn,incomplete,done,paid
         */
        isFirstTalentInfo?: boolean,
        /**
         * Booking created by this user.
         */
        isOwner?: boolean,
        /**
         * Booking in which the user has applied to participate.
         */
        isIncluded?: boolean,
        /**
         * Booking in which the user was invited.
         */
        isInvited?: boolean,
        /**
         * Booking in which a user has been added or he is the owner
         */
        isMyBooking?: boolean,
        eventType?: 'entertainment' | 'matchmaking',
        type?: 'event' | 'travel',
        status?: 'active' | 'canceled' | 'done' | 'paid',
        statuses?: Array<'active' | 'canceled' | 'done' | 'paid'>,
        /**
         * Used together with is Invited or included.
         */
        userStatus?: 'pending' | 'waitingPayment' | 'approved' | 'declined' | 'checkIn' | 'incomplete' | 'canceled' | 'done' | 'paid',
        /**
         * Used together with is Invited or included.
         */
        userStatuses?: Array<'pending' | 'waitingPayment' | 'approved' | 'declined' | 'checkIn' | 'incomplete' | 'canceled' | 'done' | 'paid'>,
        createdAtFrom?: string,
        createdAtTo?: string,
        startDateFrom?: string,
        startDateTo?: string,
        /**
         * Can be used by the admin. Send the _id
         */
        userId?: string,
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
         */
        pageFilter?: string,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
        sort?: 'createdAt' | 'startDate',
        sortDirection?: 1 | -1,
    }): CancelablePromise<PageBookingListResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking/list',
            query: {
                'search': search,
                'city': city,
                'cities': cities,
                'venue': venue,
                'isOnlyLike': isOnlyLike,
                'isInvite': isInvite,
                'isFirstTalentInfo': isFirstTalentInfo,
                'isOwner': isOwner,
                'isIncluded': isIncluded,
                'isInvited': isInvited,
                'isMyBooking': isMyBooking,
                'eventType': eventType,
                'type': type,
                'status': status,
                'statuses': statuses,
                'userStatus': userStatus,
                'userStatuses': userStatuses,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'startDateFrom': startDateFrom,
                'startDateTo': startDateTo,
                'userId': userId,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
                'sort': sort,
                'sortDirection': sortDirection,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking Invite List
     * @returns PageBookingInviteListResponseDTO List Invite Booking
     * @throws ApiError
     */
    public getBookingInviteList({
        search,
        city,
        cities,
        venue,
        isInvite,
        isFirstTalentInfo,
        isMyBooking,
        eventType,
        type,
        status,
        statuses,
        userStatus,
        userStatuses,
        createdAtFrom,
        createdAtTo,
        startDateFrom,
        startDateTo,
        userId,
        page = 1,
        limit = 10,
        pageFilter,
        isCount,
        sort = 'createdAt',
        sortDirection = -1,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Send the _id
         */
        city?: string,
        /**
         * Send the _id
         */
        cities?: Array<string>,
        /**
         * Send the _id
         */
        venue?: string,
        isInvite?: boolean,
        /**
         * Get the data of 1 talent from includedTalents or invited. We will get the data if the status is 1 of pending,waitingPayment,approved,checkIn,incomplete,done,paid
         */
        isFirstTalentInfo?: boolean,
        /**
         * Booking in which a user has been added or he is the owner
         */
        isMyBooking?: boolean,
        eventType?: 'entertainment' | 'matchmaking',
        type?: 'event' | 'travel',
        status?: 'active' | 'canceled' | 'done' | 'paid',
        statuses?: Array<'active' | 'canceled' | 'done' | 'paid'>,
        /**
         * Used together with is Invited or included.
         */
        userStatus?: 'pending' | 'waitingPayment' | 'approved' | 'declined' | 'checkIn' | 'incomplete' | 'canceled' | 'done' | 'paid',
        /**
         * Used together with is Invited or included.
         */
        userStatuses?: Array<'pending' | 'waitingPayment' | 'approved' | 'declined' | 'checkIn' | 'incomplete' | 'canceled' | 'done' | 'paid'>,
        createdAtFrom?: string,
        createdAtTo?: string,
        startDateFrom?: string,
        startDateTo?: string,
        /**
         * Can be used by the admin. Send the _id
         */
        userId?: string,
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
         */
        pageFilter?: string,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
        sort?: 'createdAt' | 'startDate',
        sortDirection?: 1 | -1,
    }): CancelablePromise<PageBookingInviteListResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking/list-invite',
            query: {
                'search': search,
                'city': city,
                'cities': cities,
                'venue': venue,
                'isInvite': isInvite,
                'isFirstTalentInfo': isFirstTalentInfo,
                'isMyBooking': isMyBooking,
                'eventType': eventType,
                'type': type,
                'status': status,
                'statuses': statuses,
                'userStatus': userStatus,
                'userStatuses': userStatuses,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'startDateFrom': startDateFrom,
                'startDateTo': startDateTo,
                'userId': userId,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
                'sort': sort,
                'sortDirection': sortDirection,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only a user with a admin role can execute the request.`,
            },
        });
    }

    /**
     * Booking Count
     * @returns number Booking Count
     * @throws ApiError
     */
    public getBookingCount({
        search,
        city,
        cities,
        venue,
        isOnlyLike,
        isInvite,
        isFirstTalentInfo,
        isOwner,
        isIncluded,
        isInvited,
        isMyBooking,
        eventType,
        type,
        status,
        statuses,
        userStatus,
        userStatuses,
        createdAtFrom,
        createdAtTo,
        startDateFrom,
        startDateTo,
        userId,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Send the _id
         */
        city?: string,
        /**
         * Send the _id
         */
        cities?: Array<string>,
        /**
         * Send the _id
         */
        venue?: string,
        isOnlyLike?: boolean,
        isInvite?: boolean,
        /**
         * Get the data of 1 talent from includedTalents or invited. We will get the data if the status is 1 of pending,waitingPayment,approved,checkIn,incomplete,done,paid
         */
        isFirstTalentInfo?: boolean,
        /**
         * Booking created by this user.
         */
        isOwner?: boolean,
        /**
         * Booking in which the user has applied to participate.
         */
        isIncluded?: boolean,
        /**
         * Booking in which the user was invited.
         */
        isInvited?: boolean,
        /**
         * Booking in which a user has been added or he is the owner
         */
        isMyBooking?: boolean,
        eventType?: 'entertainment' | 'matchmaking',
        type?: 'event' | 'travel',
        status?: 'active' | 'canceled' | 'done' | 'paid',
        statuses?: Array<'active' | 'canceled' | 'done' | 'paid'>,
        /**
         * Used together with is Invited or included.
         */
        userStatus?: 'pending' | 'waitingPayment' | 'approved' | 'declined' | 'checkIn' | 'incomplete' | 'canceled' | 'done' | 'paid',
        /**
         * Used together with is Invited or included.
         */
        userStatuses?: Array<'pending' | 'waitingPayment' | 'approved' | 'declined' | 'checkIn' | 'incomplete' | 'canceled' | 'done' | 'paid'>,
        createdAtFrom?: string,
        createdAtTo?: string,
        startDateFrom?: string,
        startDateTo?: string,
        /**
         * Can be used by the admin. Send the _id
         */
        userId?: string,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking/count',
            query: {
                'search': search,
                'city': city,
                'cities': cities,
                'venue': venue,
                'isOnlyLike': isOnlyLike,
                'isInvite': isInvite,
                'isFirstTalentInfo': isFirstTalentInfo,
                'isOwner': isOwner,
                'isIncluded': isIncluded,
                'isInvited': isInvited,
                'isMyBooking': isMyBooking,
                'eventType': eventType,
                'type': type,
                'status': status,
                'statuses': statuses,
                'userStatus': userStatus,
                'userStatuses': userStatuses,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'startDateFrom': startDateFrom,
                'startDateTo': startDateTo,
                'userId': userId,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking config
     * @returns BookingConfigResponseDTO
     * @throws ApiError
     */
    public getBookingConfig(): CancelablePromise<BookingConfigResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking/config',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
